<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="row mb-md-2">
          <div class="col-sm-12 col-md-6">
            <div class="dataTables_length">
              <label class="d-inline-flex align-items-center">
                Show&nbsp;
                <b-form-select
                    v-model="datatable.queries.per_page"
                    class="ml-1 mr-1"
                    size="sm"
                    :options="[10, 15, 25, 50, 100]"
                    @change="getAgencies"
                ></b-form-select
                >&nbsp;entries
              </label>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="dataTables_filter text-md-right">
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                    v-model="datatable.queries.search"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
              <button
                  class="ml-1 btn btn-dark-blue btn-sm"
                  @click="getAgencies"
              >
                <i class="uil uil-search-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <template v-if="!loadingAgencies">
              <div class="table-responsive mb-0">
                <b-table
                  :items="agencies"
                  :fields="datatable.columns"
                  :sort-by="datatable.queries.sort_by"
                  :sort-desc="datatable.queries.sort_desc"
                  :no-local-sorting="true"
                  @sort-changed="sortChanged"
                >
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(has_plan)="pData">
                    <feather v-if="pData.item.has_plan" type="check" class="text-success" />
                    <feather v-else type="x" class="text-danger"/>
                  </template>
                  <template v-slot:cell(actions)="data" class="actions">
                    <b-button
                      variant="link"
                      class="btn-action sign-out text-success mr-1"
                      title="Sign In"
                      :disabled="loadingImpersonate"
                      @click="impersonate(data.item.owner.id)"
                    >
                      <b-spinner v-if="loadingImpersonate" small />
                      <i v-else class="uil uil-sign-out-alt"></i>
                    </b-button>
                    <router-link
                      :to="{
                        name: 'admin.agencies.edit',
                        params: { id: data.item.id },
                      }"
                      class="btn btn-link btn-action mr-1"
                    >
                      <i class="uil uil-edit"></i>
                    </router-link>
                    <b-button
                      variant="link"
                      title="Delete"
                      class="btn-action text-danger"
                      :disabled="loadingRemove === data.item.id"
                      @click="archive(data.item)"
                    >
                      <b-spinner v-if="loadingRemove === data.item.id" small />
                      <i v-else class="uil uil-trash-alt"></i>
                    </b-button>
                  </template>
                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalAgencies }} agencies
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="datatable.queries.page"
                        :total-rows="totalAgencies"
                        :per-page="datatable.queries.per_page"
                        @change="onPageChanged"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showDeleteModal" title="Delete agency">
      <p v-if="selectedAgency"
        >Are you sure you want to delete agency "{{ selectedAgency.name }}"?</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false"
          >Cancel</b-button
        >
        <b-button variant="danger" @click="handleArchive">Delete</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      datatable: {
        columns: [
          { key: 'id', label: '#', sortable: true },
          { key: 'name', sortable: true },
          { key: 'owner.email', label: 'Email', sortable: true },
          { key: 'widgets_count', label: 'Total Widgets', sortable: true },
          { key: 'reviews_count', label: 'Total Reviews', sortable: true },
          { key: 'has_plan', label: 'Has Plan', sortable: true, width:100 },
          { key: 'actions', class: 'actions' },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          search: null,
          sort_by: 'id',
          sort_desc: false,
        },
      },
      showDeleteModal: false,
      selectedAgency: null,
      loadingAgencies: false,
      loadingImpersonate: false,
      loadingRemove: null,
    }
  },

  computed: {
    agencies() {
      return this.$store.getters['agency/all'] || []
    },

    totalAgencies() {
      return this.$store.getters['agency/total']
    },
  },

  created() {
    this.$store.dispatch('agency/setCurrentPage', 'index')
  },

  mounted() {
    if (this.$store.getters['agency/all'] === null) {
      this.getAgencies()
    } else {
      this.datatable.queries = this.$store.getters['agency/queries']
    }
  },

  methods: {
    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.page = 1
      this.getAgencies()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getAgencies()
    },

    getAgencies() {
      this.loadingAgencies = true

      this.$store
        .dispatch('agency/getAll', {
          queries: this.datatable.queries,
          page: 'index',
        })
        .then(() => {
          this.loadingAgencies = false
        })
        .catch(() => {
          this.loadingAgencies = false
        })
    },

    impersonate(userId) {
      this.loadingImpersonate = true

      this.$store
        .dispatch('auth/impersonate', userId)
        .then((res) => {
          this.$store
            .dispatch('auth/getProfile')
            .then((user) => {
              this.$router.push('/' + user.role.toLocaleLowerCase()+ '/widgets')
              this.loadingImpersonate = false
            })
            .catch(() => {
              this.loadingImpersonate = false
            })
        })
        .catch(() => {
          this.loadingImpersonate = false
        })
    },

    archive(agency) {
      this.showDeleteModal = true
      this.selectedAgency = agency
    },

    handleArchive() {
      this.showDeleteModal = false
      this.loadingRemove = this.selectedAgency.id
      this.$store
        .dispatch('agency/archive', this.selectedAgency.id)
        .then(() => {
          this.loadingRemove = null
          this.getAgencies()
        })
        .catch(() => {
          this.loadingRemove = null
        })
    },
  },
}
</script>
